import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, switchMap, timer} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {

  constructor(private translateService: TranslateService) {
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return timer(0) // Prevents this method from being executed before the translate-server initialized.
      .pipe(
        switchMap(() => {
          const requestWithLanguageHeader = req.clone({
            headers: req.headers
              .set('language', this.translateService.currentLang.split('-')[0])
              .set('locale', this.translateService.currentLang)
          });

          return next.handle(requestWithLanguageHeader);
        })
      );
  }
}
