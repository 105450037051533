import {EnvironmentType} from "./environment.type";

export const environment: EnvironmentType = {
  production: true,
  api: {
    basePath: 'https://bootjes-en-broodjes-ticketing-api-test.azurewebsites.net'
  },
  articleImageBaseUrl: 'https://webservicesbootjesenbroodjes.recreatex.be/WebShopImageService.svc/',
  companyAddress: 'Blauwpoortsbrug 1 Leiden',
  foodOrderWarningMillis: 15 * 60 * 1000, // 15 minutes
  appInsights: {
    instrumentationKey: '43d064e3-cda3-4db8-bd60-5c45eacc0c22'
  },
  defaultGroupTourId: "bc808547-93a2-ea11-a2e0-d9c772e4e9da"
};
