<mat-card>
  <mat-card-content>
    <mat-icon>check_circle</mat-icon>
    <p class="centered">
      {{'PAGES.PRODUCTS.GROUP.STEPS.THANKS' | translate}}
    </p>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <a mat-flat-button color="primary" href="https://bootjesenbroodjes.nl">
      {{'PAGES.PAYMENT.BACK_TO_WEBSITE' | translate}}
    </a>
  </mat-card-content>
</mat-card>
