import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PaymentProcessingComponent} from "./pages/payment-processing/payment-processing.component";
import {ThanksComponent} from "./pages/products/group/thanks/thanks.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";

const routes: Routes = [
  {path: 'huren/sloep', loadChildren: () => import('./pages/products/rental-boat/rental-boat.module').then(m => m.RentalBoatModule)},
  {path: 'tour', loadChildren: () => import('./pages/products/tour/tour.module').then(m => m.TourModule)},
  {path: 'groepsvaart', loadChildren: () => import('./pages/products/group/group.module').then(m => m.GroupModule)},
  {path: 'special', loadChildren: () => import('./pages/products/specials/specials.module').then(m => m.SpecialsModule)},
  {path: 'giftcard', loadChildren: () => import('./pages/products/giftcard/giftcard.module').then(m => m.GiftcardModule)},
  {path: 'groepsvaart/bedankt', component: ThanksComponent},
  {path: 'processing', component: PaymentProcessingComponent},
  {path: '**', pathMatch: 'full', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
