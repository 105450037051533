import {Injectable} from '@angular/core';
import {ComponentType} from "@angular/cdk/overlay";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

export interface StaticDialog<D, R> {
  getDialogInput(input: D | null): void;

  getValue(): R | null;
}

type StaticDialogReturnType<T> = T extends StaticDialog<unknown, infer R> ? R : never;

@Injectable({
  providedIn: 'root'
})
export class StaticDialogService {

  constructor(private matDialog: MatDialog) { }

  public open<T extends StaticDialog<D, R>, D, R = StaticDialogReturnType<T>>(component: ComponentType<T>, config: MatDialogConfig<D>): MatDialogRef<T, R> {
    const dialogRef = this.matDialog.open<T, D, R>(component, config);

    dialogRef.componentInstance.getDialogInput(config.data ?? null);

    return dialogRef;
  }
}
