/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { FindAvailableBoatsRequest } from '../models/FindAvailableBoatsRequest';
import type { RentalBoatOption } from '../models/RentalBoatOption';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class RentalBoatsApiService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns RentalBoatOption Success
     * @throws ApiError
     */
    public postApiRentalBoats(
        requestBody?: FindAvailableBoatsRequest,
    ): Observable<Array<RentalBoatOption>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/RentalBoats',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns string Success
     * @throws ApiError
     */
    public getApiRentalBoatsAvailability(): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/RentalBoats/Availability',
        });
    }
}
