import {concat, defer, distinctUntilChanged, map, Observable, of, OperatorFunction, startWith, switchMap} from "rxjs";

// This tap will execute once, at the beginning of a stream, no values have to be emitted, kind of like startsWith.
export function startWithTap<T>(callback: () => void) {
  return (source: Observable<T>) =>
    defer(() => {
      callback();
      return source;
    });
}

export function distinctUntilChangedObj<T>() {
  return distinctUntilChanged<T>((a, b) => JSON.stringify(a) === JSON.stringify(b));
}

export function reEmitOn<T>(reload$: Observable<unknown>) {
  return (source: Observable<T>) => source.pipe(
    switchMap(value => reload$.pipe(map(() => value), startWith(value)))
  );
}

export type LoadingState = {loading: boolean};

export function withLoadingState<T>(): OperatorFunction<T, T | LoadingState> {
  return source => {
    return concat(
      of({loading: true}),
      source.pipe(
        switchMap(value => concat(of({loading: false}), of(value)))
      )
    );
  };
}

export function isLoadingState<T>(value: T | LoadingState): value is LoadingState {
  return typeof value === 'object' && value !== null && value !== undefined && 'loading' in value;
}

export function withoutLoadingState<T>(value: T | LoadingState): value is T {
  return typeof value !== 'object' || value === null || !('loading' in value);
}
