import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {FormControl} from "@angular/forms";
import {FindArticlesRequest, UpsellApiService} from "../api/generated";
import {
  combineLatest,
  defer,
  filter,
  map,
  merge,
  Observable,
  ObservedValueOf,
  shareReplay,
  startWith,
  Subject,
  switchMap
} from "rxjs";
import {tryRecoverFromErrors} from "../helpers/RecoverableObservable";
import {ErrorService} from "./error.service";
import {notNullOrUndefined} from "../helpers/UtilityFunctions";
import {LoadingState, reEmitOn, withLoadingState} from "../helpers/CustomRxjsOperators";

type ArticleType = ObservedValueOf<ReturnType<UpsellApiService['postApiUpsellArticles']>>[0];

@Injectable({
  providedIn: 'root'
})
export class UpsellService {

  constructor(
    private translateService: TranslateService,
    private upsellApiService: UpsellApiService,
    private errorService: ErrorService
  ) {
  }

  private activeObservable: Observable<ArticleType[] | LoadingState> | null = null;
  private reload$ = new Subject<void>();

  public getLocalizedArticlesObservable(expositionPeriodControl: FormControl<string | null> | null, expositionControl: FormControl<string | null> | null) {
    if (expositionPeriodControl === null && expositionControl === null) {
      throw new Error('Invariant, at least one control must be defined');
    }

    if (this.activeObservable !== null) {
      return this.activeObservable;
    }

    const expositionPeriodValueToApiBody$ = expositionPeriodControl?.valueChanges
      .pipe(
        startWith(expositionPeriodControl?.value),
        map(expositionPeriodId => ({expositionPeriodId} as FindArticlesRequest)),
        reEmitOn(this.reload$)
      );

    const expositionValueToApiBody$ = expositionControl?.valueChanges
      .pipe(
        startWith(expositionControl?.value),
        map(expositionId => ({expositionId} as FindArticlesRequest)),
        reEmitOn(this.reload$)
      );

    const observables = [expositionPeriodValueToApiBody$, expositionValueToApiBody$].filter(notNullOrUndefined);

    const apiInput$ = merge(...observables);

    const language$ = defer(() => this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)));

    const articlesWithLoadingState = combineLatest([apiInput$, language$])
      .pipe(
        filter(([apiInput]) => notNullOrUndefined(apiInput.expositionId) || notNullOrUndefined(apiInput.expositionPeriodId)),
        switchMap(([apiInput]) => {
          return this.upsellApiService.postApiUpsellArticles(apiInput)
            .pipe(
              withLoadingState(),
              tryRecoverFromErrors(() => this.errorService.showErrorDialogWithRetry())
            );
        }),
        shareReplay(1)
      );

    this.activeObservable = articlesWithLoadingState;
    return articlesWithLoadingState;
  }

  update() {
    this.reload$.next();
  }
}
