import {NativeDateAdapter} from "@angular/material/core";
import {Injectable} from "@angular/core";

@Injectable()
export class DutchDateAdapter extends NativeDateAdapter {

  override parse(value: string): Date | null {
    const it = value.split('-');
    if (it.length == 3) {
      return new Date(+it[2], +it[1] - 1, +it[0], 12);
    }

    return null;
  }

  override format(date: Date, displayFormat: unknown): string {
    return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }

}
