import {ErrorHandler, Injectable} from '@angular/core';
import {map} from "rxjs";
import {TryAgainObservable} from "../helpers/RecoverableObservable";
import {StaticDialogService} from "./static-dialog.service";
import {ErrorDialogComponent} from "../dialogs/error-dialog/error-dialog.component";
import {ErrorRetryDialogComponent} from "../dialogs/error-retry-dialog/error-retry-dialog.component";
import {LoggingService} from "./logging.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ErrorService extends ErrorHandler {

  constructor(
    private staticDialogService: StaticDialogService,
    private loggingService: LoggingService
  ) {
    super();
  }

  public showErrorDialogWithRetry(message?: string): TryAgainObservable {
    return this.staticDialogService.open(ErrorRetryDialogComponent, { data: {message: message ?? null}, disableClose: true })
      .afterClosed()
      .pipe(
        map(retry => ({tryAgain: retry?.tryAgain ?? false}))
      );
  }

  public showErrorDialogWithoutRetry(message?: string): TryAgainObservable {
    return this.staticDialogService.open(ErrorRetryDialogComponent, { data: {message: message ?? null, withRetry: false }, disableClose: true })
      .afterClosed()
      .pipe(
        map(retry => ({tryAgain: retry?.tryAgain ?? false}))
      );
  }

  showError(message: string) {
    return this.staticDialogService.open(ErrorDialogComponent, { disableClose: false, data: {error: message} });
  }

  override handleError(error: Error): void {
    if (!environment.production) {
      console.error(error);
    }

    this.loggingService.logException(error);
  }
}
