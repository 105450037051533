import {Injectable} from '@angular/core';
import {fromEvent, map, startWith, throttleTime} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  activeLayout: 'desktop' | 'mobile' | 'smartphone' = 'desktop';

  constructor() {
    const resizeObservable$ = fromEvent(window, 'resize');

    resizeObservable$
      .pipe(
        throttleTime(50),
        map(() => window.innerWidth),
        startWith(window.innerWidth),
      ).subscribe(width => this.activeLayout = this.determineActiveLayout(width));
  }

  private determineActiveLayout(width: number): this['activeLayout'] {
    if (width > 1024) {
      return 'desktop';
    }

    if (width < 768) {
      return 'smartphone';
    }

    return 'mobile';
  }
}
