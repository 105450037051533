import {map, Observable, retry, timer} from "rxjs";
import {ApiError} from "../api/generated";

export type TryAgainObservable = Observable<{ tryAgain: boolean }>;

export function tryRecoverFromErrors<T>(retrySignalFn: (err: unknown) => TryAgainObservable) {
  const handleError = (error: unknown, retryCount: number) => {
    if (error instanceof ApiError && error.status !== 400) {
      if (retryCount < 3) {
        return timer(500);
      }
    }

    return retrySignalFn(error)
      .pipe(
        map(({tryAgain}) => {
          if (tryAgain) {
            return {tryAgain};
          }

          throw error;
        })
      );
  };

  return (source: Observable<T>) => {
    return source.pipe(
      retry({delay: handleError, resetOnSuccess: false})
    );
  };
}
