<div mat-dialog-title>
  <mat-icon>signal_cellular_connected_no_internet_4_bar</mat-icon>
  {{'DIALOGS.ERROR_DIALOG.TITLE' | translate}}
</div>

<div mat-dialog-content>
  @if (errorMessage !== null) {
    {{errorMessage}}
  }

  @if (errorMessage === null) {
    {{'DIALOGS.ERROR_DIALOG.BODY' | translate}}
  }
</div>

<div mat-dialog-actions>
  <button mat-flat-button [matDialogClose]="null">{{'ERROR_DIALOG.CLOSE' | translate}}</button>
</div>

