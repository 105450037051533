
@switch (responsiveService.activeLayout) {
  @case ('smartphone') {
    <header class="phone-header">
      <nav>
        <a mat-flat-button href="https://bootjesenbroodjes.nl">
          <mat-icon>close</mat-icon>
          {{!onProcessingPage ? ('HEADER.CANCEL_ORDER' | translate) : ('HEADER.RETURN_TO_WEBSITE' | translate)}}
        </a>
      </nav>
      <div class="languages">
        @for (language of languageOptionKeys; track language) {
          <button class="language-button" mat-icon-button (click)="selectLanguage(language)" [class.selected]="activeLanguage === language" [class.primary-border]="activeLanguage === language">
            <mat-icon [svgIcon]="language"></mat-icon>
          </button>
        }
      </div>
    </header>
  }
  @case ('mobile') {
    <header>
      <img src="assets/logo_mobile.png" alt="Logo">
      <nav>
        <a mat-flat-button href="https://bootjesenbroodjes.nl">
          {{!onProcessingPage ? ('HEADER.CANCEL_ORDER' | translate) : ('HEADER.RETURN_TO_WEBSITE' | translate)}}
        </a>
      </nav>
      <div class="languages">
        @for (language of languageOptionKeys; track language) {
          <button class="language-button" mat-icon-button (click)="selectLanguage(language)" [class.selected]="activeLanguage === language" [class.primary-border]="activeLanguage === language">
            <mat-icon [svgIcon]="language"></mat-icon>
          </button>
        }
      </div>
    </header>
  }
  @default {
    <header>
      <img src="assets/logo.png" alt="Logo">
      <nav>
        <a mat-flat-button href="https://bootjesenbroodjes.nl">
          <mat-icon>close</mat-icon>
          {{!onProcessingPage ? ('HEADER.CANCEL_ORDER' | translate) : ('HEADER.RETURN_TO_WEBSITE' | translate)}}
        </a>
      </nav>
      <div class="languages">
        @for (language of languageOptionKeys; track language) {
          <button class="language-button" mat-icon-button (click)="selectLanguage(language)" [class.selected]="activeLanguage === language" [class.primary-border]="activeLanguage === language">
            <mat-icon [svgIcon]="language"></mat-icon>
          </button>
        }
      </div>
    </header>
  }
}

<div id="main-content" [ngClass]="responsiveService.activeLayout">
  <router-outlet></router-outlet>
</div>

<mat-menu #languageMenu="matMenu" yPosition="below" xPosition="after">
  @for (language of availableLanguages; track language) {
    <button mat-menu-item (click)="selectLanguage(language)">
      <mat-icon [svgIcon]="language"></mat-icon>
      <span>{{languageOptionMap[language].nativeName}}</span>
    </button>
  }
</mat-menu>
