import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FormControlErrorsComponent} from "./form-control-errors/form-control-errors.component";
import {ArticleTileComponent} from './extra-article-tile/article-tile.component';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatChipsModule} from "@angular/material/chips";
import {StaticDialogService} from "../services/static-dialog.service";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {PaymentMethodSelectComponent} from './payment-method-select/payment-method-select.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {PaymentApiService, UpsellApiService} from "../api/generated";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ErrorService} from "../services/error.service";
import {NumberControlComponent} from './number-control/number-control.component';
import {MatInputModule} from "@angular/material/input";
import {MatRippleModule} from "@angular/material/core";
import {UpsellArticlesComponent} from './upsell-articles/upsell-articles.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PersonalDetailsComponent} from './personal-details/personal-details.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatStepperModule} from "@angular/material/stepper";
import {UpsellService} from "../services/upsell.service";
import {DiscountVoucherCardComponent} from './discount-voucher-card/discount-voucher-card.component';
import {GiftCardOrderComponent} from './gift-card-order/gift-card-order.component';
import {PipesModule} from "../pipes/pipes.module";
import {GiftCardOrderSubmittableComponent} from "./gift-card-order-submittable/gift-card-order-submittable.component";


@NgModule({
  declarations: [
    FormControlErrorsComponent,
    ArticleTileComponent,
    PaymentMethodSelectComponent,
    NumberControlComponent,
    UpsellArticlesComponent,
    PersonalDetailsComponent,
    DiscountVoucherCardComponent,
    GiftCardOrderComponent,
    GiftCardOrderSubmittableComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatTooltipModule,
    TranslateModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatStepperModule,
    PipesModule
  ],
  exports: [
    FormControlErrorsComponent,
    ArticleTileComponent,
    PaymentMethodSelectComponent,
    NumberControlComponent,
    UpsellArticlesComponent,
    PersonalDetailsComponent,
    DiscountVoucherCardComponent,
    GiftCardOrderComponent,
    GiftCardOrderSubmittableComponent
  ],
  providers: [
    StaticDialogService,
    PaymentApiService,
    CurrencyPipe,
    ErrorService,
    UpsellService,
    UpsellApiService
  ]
})
export class ComponentsModule {
}
