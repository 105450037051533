import {FormControl} from "@angular/forms";
import {Observable, startWith} from "rxjs";
import {MatStepper} from "@angular/material/stepper";
import {DateTime} from "luxon";
import {Signal} from "@angular/core";

export function notNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function nullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

export function throwCoalesceException(field: string): never {
  throw new Error(`Invariant error, ${field} may not be null.`);
}

export function CountGroupStringArray(items: string[]): Record<string, number> {
  return items
    .reduce((map, word) =>
        Object.assign(map, {
          [word]: (map[word])
            ? map[word] + 1
            : 1,
        }),
      {} as Record<string, number>
    );
}

type RequireKeys<T extends Record<string, unknown>, K extends keyof T> = Pick<{ [key in K]-?: NonNullable<T[key]> }, K>;

// Made for filter functions, you can pass an array of keys, it will type safely remove the nullability of these properties.
export function hasRequiredPropertiesFn<T extends Record<string, unknown>, K extends keyof T>(requiredKeys: K[]) {
  return (obj: T): obj is T & RequireKeys<T, K> => Object.keys(obj).filter(key => key in requiredKeys).every(key => obj[key] !== null && obj[key] !== undefined);
}

export function hasRequiredProperties<T extends Record<string, unknown>, K extends keyof T>(obj: T, requiredKeys: K[]): obj is T & RequireKeys<T, K> {
  return Object.keys(obj).filter(key => key in requiredKeys).every(key => obj[key] !== null && obj[key] !== undefined);
}

export function groupBy<T, K extends string | number | symbol>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
  return list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
}

export function makeFormControlObservable<T>(control: FormControl<T>): Observable<T> {
  return control.valueChanges.pipe(startWith(control.value));
}

export function makeStepperIndicatorMethod(stepper: MatStepper): (index: number) => string {
  return index => {
    const interacted = stepper.steps.get(index)?.interacted ?? false;

    if (index === stepper.steps.length - 1) return 'point_of_sale';
    if (index === stepper.selectedIndex) return 'edit';
    if (interacted) return 'done';
    return 'number';
  };
}

export function makeNumericDateLookupTable(dates: Array<DateTime>) {
  return dates.reduce((acc, date) => {
    return {
      ...acc,
      [Math.floor(date.toMillis() / 86400000)]: true
    };
  }, {} as {[day: number]: boolean});
}

export type SignalValue<S> = S extends Signal<infer Value> ? Value : never;
