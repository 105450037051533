import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {HttpLoaderFactory} from "./helpers/TranslationLoaderFactory";
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {DutchDateAdapter} from "./helpers/MaterialDateAdapter";
import {LanguageHttpInterceptor} from "./api/LanguageHttpInterceptor";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {DATE_PIPE_DEFAULT_OPTIONS, DatePipeConfig, registerLocaleData} from '@angular/common';
import LocaleNl from '@angular/common/locales/nl';
import LocaleEn from '@angular/common/locales/en';
import LocaleDe from '@angular/common/locales/de';
import {PaymentProcessingComponent} from './pages/payment-processing/payment-processing.component';
import {MatCardModule} from "@angular/material/card";
import {register as registerSwiper} from 'swiper/element/bundle';
import {Settings as LuxonSettings} from "luxon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {DialogsModule} from "./dialogs/dialogs.module";
import {ErrorService} from "./services/error.service";
import {NotFoundComponent} from './pages/not-found/not-found.component';

// Configure the time zone
LuxonSettings.defaultZone = "Europe/Amsterdam";

@NgModule({
  declarations: [
    AppComponent,
    PaymentProcessingComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DialogsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatNativeDateModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatProgressSpinnerModule,
    AppRoutingModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {provide: DateAdapter, useClass: DutchDateAdapter},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageHttpInterceptor, multi: true},
    {provide: ErrorHandler, useClass: ErrorService},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: ''} as DatePipeConfig}
  ],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}

registerLocaleData(LocaleNl);
registerLocaleData(LocaleEn);
registerLocaleData(LocaleDe);
registerSwiper();
