import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArticleInfoDialogComponent} from "./article-info-dialog/article-info-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {SendingTransactionComponent} from './sending-transaction/sending-transaction.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SwiperDirective} from "../directives/webcomponents/swiper.directive";
import {ErrorRetryDialogComponent} from './error-retry-dialog/error-retry-dialog.component';
import {FoodOrderExpiryComponent} from './food-order-expiry/food-order-expiry.component';
import {ComponentsModule} from "../components/components.module";


@NgModule({
  declarations: [
    ArticleInfoDialogComponent,
    ErrorDialogComponent,
    SendingTransactionComponent,
    ErrorRetryDialogComponent,
    FoodOrderExpiryComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SwiperDirective,
    ComponentsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DialogsModule {
}
