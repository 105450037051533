<mat-card>
  @if (checkoutStatus === null) {
    <mat-card-content>
      <mat-spinner></mat-spinner>
      <p class="spinner-label">{{'PAGES.PAYMENT.CHECKING_STATUS' | translate}}</p>
    </mat-card-content>
  } @else {
    <mat-card-content>
      <h1>{{statusInfo?.titleTranslation | async}}</h1>
      <mat-icon [ngClass]="checkoutStatus.status?.toLowerCase()">{{statusInfo?.icon}}</mat-icon>
      <p class="centered">{{statusInfo?.bodyTranslation | async}}</p>
    </mat-card-content>
  }
</mat-card>


@if (checkoutStatus?.confirmationUrl) {
  <mat-card>
    <mat-card-content>
      @if (checkoutStatus?.confirmationUrl) {
        <button mat-flat-button color="primary"
          (click)="downloadConfirmation()">
          {{'PAGES.PAYMENT.CONFIRMATION_DOWNLOAD' | translate}}
        </button>
      }
    </mat-card-content>
  </mat-card>
}

@if (checkoutStatus?.ticketUrl) {
  <mat-card>
    <mat-card-content>
      @if (checkoutStatus?.ticketUrl) {
        <button mat-flat-button color="primary"
          (click)="downloadTickets()">
          {{'PAGES.PAYMENT.TICKETS_DOWNLOAD' | translate}}
        </button>
      }
    </mat-card-content>
  </mat-card>
}

@if (checkoutStatus?.giftcardUrl) {
  <mat-card>
    <mat-card-content>
      @if (checkoutStatus?.giftcardUrl) {
        <button mat-flat-button color="primary"
          (click)="downloadGiftcard()">
          {{'PAGES.PAYMENT.GIFTCARD_DOWNLOAD' | translate}}
        </button>
      }
    </mat-card-content>
  </mat-card>
}

@if (checkoutStatus !== null) {
  <mat-card>
    <mat-card-content>
      <a mat-flat-button color="primary" href="https://bootjesenbroodjes.nl">
        {{'PAGES.PAYMENT.BACK_TO_WEBSITE' | translate}}
      </a>
    </mat-card-content>
  </mat-card>
}
