import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from "./environments/environment";
import {OpenAPI} from "./app/api/generated";

OpenAPI.BASE = environment.api.basePath;

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
