import {Injectable} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from "../../environments/environment";
import {notNullOrUndefined} from "../helpers/UtilityFunctions";

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  appInsights: ApplicationInsights | null = null;

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights?.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: unknown }) {
    this.appInsights?.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: unknown }) {
    this.appInsights?.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights?.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: unknown }) {
    this.appInsights?.trackTrace({ message: message}, properties);
  }

  public initialize() {
    if (notNullOrUndefined(environment.appInsights?.instrumentationKey)) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights?.instrumentationKey,
          enableAutoRouteTracking: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }
}
