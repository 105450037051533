import {Component} from '@angular/core';
import {StaticDialog} from "../../services/static-dialog.service";
import {TryAgainObservable} from "../../helpers/RecoverableObservable";
import {Observable} from "rxjs";

export type TryAgainObservableContents = TryAgainObservable extends Observable<infer T> ? T : never;
export interface ErrorInput {
  error: string;
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements StaticDialog<ErrorInput, TryAgainObservableContents> {

  errorMessage: string | null = null;

  getDialogInput(input: ErrorInput | null): void {
    this.errorMessage = input?.error ?? null;
  }

  getValue(): TryAgainObservableContents | null {
    return null;
  }
}
