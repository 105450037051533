/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { TourAvailabilityRequest } from '../models/TourAvailabilityRequest';
import type { TourAvailabilityResponse } from '../models/TourAvailabilityResponse';
import type { TourPricesResponse } from '../models/TourPricesResponse';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class TourApiService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @returns TourAvailabilityResponse Success
     * @throws ApiError
     */
    public getApiTourAvailability(): Observable<TourAvailabilityResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Tour/Availability',
        });
    }
    /**
     * @param requestBody
     * @returns TourAvailabilityResponse Success
     * @throws ApiError
     */
    public postApiTourAvailabilityForDay(
        requestBody?: TourAvailabilityRequest,
    ): Observable<TourAvailabilityResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Tour/AvailabilityForDay',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param dateTime
     * @returns TourPricesResponse Success
     * @throws ApiError
     */
    public getApiTourPrices(
        dateTime?: string,
    ): Observable<TourPricesResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Tour/prices',
            query: {
                'dateTime': dateTime,
            },
        });
    }
}
