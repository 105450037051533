import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DutchDatePipe} from "./dutch-date.pipe";


@NgModule({
  declarations: [
    DutchDatePipe
  ],
  exports: [
    DutchDatePipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
