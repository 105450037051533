import {Component, OnInit} from '@angular/core';
import {CheckoutStatusResponse, PaymentApiService, TransactionStatus} from "../../api/generated";
import {ActivatedRoute} from "@angular/router";
import {EMPTY, exhaustMap, filter, map, Observable, switchMap, take, timer} from "rxjs";
import {notNullOrUndefined} from "../../helpers/UtilityFunctions";
import {TranslateService} from "@ngx-translate/core";
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.scss']
})
export class PaymentProcessingComponent implements OnInit {

  statuses: {
    [key in TransactionStatus]: {
      titleTranslation: Observable<string>,
      bodyTranslation: Observable<string>
      icon: string
    }
  } = {
    Completed: {
      titleTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.COMPLETED.TITLE'),
      bodyTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.COMPLETED.BODY'),
      icon: 'check_circle'
    },
    Pending: {
      titleTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.PENDING.TITLE'),
      bodyTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.PENDING.BODY'),
      icon: 'pending'
    },
    Failed: {
      titleTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.FAILED.TITLE'),
      bodyTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.FAILED.BODY'),
      icon: 'warning'
    },
    Error: {
      titleTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.ERROR.TITLE'),
      bodyTranslation: this.translationService.get('PAGES.PAYMENT.STATUS.ERROR.BODY'),
      icon: 'error'
    },
  };

  checkoutStatus: CheckoutStatusResponse | null = null;

  constructor(
    private paymentApiService: PaymentApiService,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map(params => params["transaction"] as string | undefined),
        filter(notNullOrUndefined),
        switchMap(transactionId => timer(0, 1000).pipe(map(() => transactionId))),
        exhaustMap(transactionId => {
          return this.paymentApiService
            .postApiPaymentCheckStatus({id: transactionId})
            .pipe(catchError(err => {
              console.error(err);
              return EMPTY;
            }));
        }),
        filter(apiResponse => apiResponse.status !== 'Pending'),
        take(1)
      ).subscribe(checkoutStatus => this.checkoutStatus = checkoutStatus);
  }

  get statusInfo() {
    if (this.checkoutStatus?.status === undefined) {
      return null;
    }

    return this.statuses[this.checkoutStatus.status];
  }

  downloadConfirmation() {
    const url = this.checkoutStatus?.confirmationUrl ?? null;
    if (url === null) {
      return;
    }

    this.downloadFile(url, 'order');
  }

  downloadTickets() {
    const url = this.checkoutStatus?.ticketUrl ?? null;
    if (url === null) {
      return;
    }

    this.downloadFile(url, 'tickets');
  }


  downloadGiftcard() {
    const url = this.checkoutStatus?.giftcardUrl ?? null;
    if (url === null) {
      return;
    }

    this.downloadFile(url, 'giftcard');
  }

  downloadFile(url: string, name: string) {
    this.paymentApiService.http.get(environment.api.basePath + url, {responseType: 'blob'})
      .subscribe((data) => {
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `Broodjes en boodjes ${name} ${this.activatedRoute.snapshot.queryParams['transaction']}`;
        link.click();
      });
  }
}
