/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { Article } from '../models/Article';
import type { CalculateDiscountsRequest } from '../models/CalculateDiscountsRequest';
import type { CalculateDiscountsResponse } from '../models/CalculateDiscountsResponse';
import type { CheckCouponRequest } from '../models/CheckCouponRequest';
import type { CheckCouponResponse } from '../models/CheckCouponResponse';
import type { CheckGiftCardRequest } from '../models/CheckGiftCardRequest';
import type { CheckGiftCardResponse } from '../models/CheckGiftCardResponse';
import type { CheckoutRequest } from '../models/CheckoutRequest';
import type { CheckoutResponse } from '../models/CheckoutResponse';
import type { CheckoutStatusRequest } from '../models/CheckoutStatusRequest';
import type { CheckoutStatusResponse } from '../models/CheckoutStatusResponse';
import type { GiftCardCheckoutRequest } from '../models/GiftCardCheckoutRequest';
import type { GiftCardCheckoutResponse } from '../models/GiftCardCheckoutResponse';
import type { PaymentMethodsResponse } from '../models/PaymentMethodsResponse';
import type { VoucherStatus } from '../models/VoucherStatus';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class PaymentApiService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @returns PaymentMethodsResponse Success
     * @throws ApiError
     */
    public getApiPaymentMethods(): Observable<PaymentMethodsResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Payment/methods',
        });
    }
    /**
     * @returns Article Success
     * @throws ApiError
     */
    public getApiPaymentCreditcardProductDetails(): Observable<Article> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/Payment/CreditcardProductDetails',
        });
    }
    /**
     * @param requestBody
     * @returns VoucherStatus Success
     * @throws ApiError
     */
    public postApiPaymentValidateCoupons(
        requestBody?: Array<string>,
    ): Observable<Array<VoucherStatus>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Payment/ValidateCoupons',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns CheckoutStatusResponse Success
     * @throws ApiError
     */
    public postApiPaymentCheckStatus(
        requestBody?: CheckoutStatusRequest,
    ): Observable<CheckoutStatusResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Payment/checkStatus',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns CalculateDiscountsResponse Success
     * @throws ApiError
     */
    public postApiPaymentCalculate(
        requestBody?: CalculateDiscountsRequest,
    ): Observable<CalculateDiscountsResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Payment/calculate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns CheckoutResponse Success
     * @throws ApiError
     */
    public postApiPaymentCheckout(
        requestBody?: CheckoutRequest,
    ): Observable<CheckoutResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Payment/checkout',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns GiftCardCheckoutResponse Success
     * @throws ApiError
     */
    public postApiPaymentCheckoutGiftCard(
        requestBody?: GiftCardCheckoutRequest,
    ): Observable<GiftCardCheckoutResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Payment/checkoutGiftCard',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CheckGiftCardResponse Success
     * @throws ApiError
     */
    public postApiPaymentCheckGiftCard(
        requestBody?: CheckGiftCardRequest,
    ): Observable<CheckGiftCardResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Payment/CheckGiftCard',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CheckCouponResponse Success
     * @throws ApiError
     */
    public postApiPaymentCheckCoupon(
        requestBody?: CheckCouponRequest,
    ): Observable<CheckCouponResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Payment/CheckCoupon',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }
}
