import {Component} from '@angular/core';
import {StaticDialog} from "../../services/static-dialog.service";
import {MatDialogRef} from "@angular/material/dialog";
import {TryAgainObservableContents} from "../error-dialog/error-dialog.component";

type Input = {message: string | null, withRetry?: boolean};

@Component({
  selector: 'app-error-retry-dialog',
  templateUrl: './error-retry-dialog.component.html',
  styleUrls: ['./error-retry-dialog.component.scss']
})
export class ErrorRetryDialogComponent implements StaticDialog<Input, TryAgainObservableContents> {

  errorMessage: string | null = null;
  retryAvailable = true;

  constructor(public dialogRef: MatDialogRef<void, TryAgainObservableContents>) {
  }

  getDialogInput(input: Input | null): void {
    this.errorMessage = input?.message ?? null;
    this.retryAvailable = input?.withRetry ?? true;
  }

  getValue(): TryAgainObservableContents | null {
    return null;
  }

  retry() {
    this.dialogRef.close({tryAgain: true});
  }

  close() {
    this.dialogRef.close({tryAgain: false});
  }
}
