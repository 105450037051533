/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { GroupInformationRequest } from '../models/GroupInformationRequest';
import type { GroupInformationResponse } from '../models/GroupInformationResponse';
import type { GroupTourSubmitRequest } from '../models/GroupTourSubmitRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class GroupApiService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns GroupInformationResponse Success
     * @throws ApiError
     */
    public postApiGroupGroupDetails(
        requestBody?: GroupInformationRequest,
    ): Observable<GroupInformationResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Group/GroupDetails',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public postApiGroupSubmit(
        requestBody?: GroupTourSubmitRequest,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Group/Submit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
