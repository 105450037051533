<div mat-dialog-title>
  <mat-icon>signal_cellular_connected_no_internet_4_bar</mat-icon>
  {{'DIALOGS.ERROR_DIALOG.TITLE' | translate}}
</div>

<div mat-dialog-content>
  @if (errorMessage !== null) {
    {{errorMessage}}
  }

  @if (errorMessage === null) {
    {{'DIALOGS.ERROR_DIALOG.BODY' | translate}}
  }
</div>

<div mat-dialog-actions>
  @if (retryAvailable) {
    <button mat-flat-button (click)="retry()">{{'DIALOGS.ERROR_DIALOG.RETRY' | translate}}</button>
  }
  @if (!retryAvailable) {
    <button mat-flat-button (click)="close()">{{'DIALOGS.ERROR_DIALOG.CLOSE' | translate}}</button>
  }
</div>

