/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { SpecialAvailabilityRequest } from '../models/SpecialAvailabilityRequest';
import type { SpecialAvailabilityResponse } from '../models/SpecialAvailabilityResponse';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class SpecialsApiService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns SpecialAvailabilityResponse Success
     * @throws ApiError
     */
    public postApiSpecialsAvailability(
        requestBody?: SpecialAvailabilityRequest,
    ): Observable<SpecialAvailabilityResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/Specials/Availability',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
